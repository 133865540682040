import React, { useState, useEffect, useRef } from 'react';
import './Video.css';

function VideoPlayer() {
  const playerRef = useRef(null);
  const [videoId, setVideoId] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState(0);
  const [highlights, setHighlights] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [currentTag, setCurrentTag] = useState('');

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-player', {
        events: {
          onStateChange: onPlayerStateChange,
          onReady: onPlayerReady,
        },
      });
    };
  }, []);

  const onPlayerReady = (event) => {
    const fetchDuration = () => {
      const videoDuration = event.target.getDuration();
      if (videoDuration > 0) {
        setDuration(videoDuration);
      } else {
        setTimeout(fetchDuration, 500);
      }
    };
    fetchDuration();
  };

  const onPlayerStateChange = () => {
    if (playerRef.current) {
      const interval = setInterval(() => {
        const time = playerRef.current.getCurrentTime();
        setCurrentTime(time);
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  const handleVideoIdSubmit = (e) => {
    e.preventDefault();
    playerRef.current.loadVideoById(videoId);
  };

  const handleStart = () => {
    setStartTime(currentTime);
    setIsStarted(true);
  };

  const handleStop = () => {
    if (startTime !== null && currentTag) {
      const endTime = currentTime;
      const newHighlight = { start: startTime, end: endTime, tag: currentTag };
      setHighlights([...highlights, newHighlight]);
      setStartTime(null);
      setIsStarted(false);
    }
  };

  const handleTagSelection = (tag) => {
    setCurrentTag(tag);
  };

  const updateHighlight = (index, type, newTime) => {
    setHighlights((prevHighlights) =>
      prevHighlights.map((highlight, i) =>
        i === index
          ? {
              ...highlight,
              [type]: Math.max(0, Math.min(duration, newTime)),
            }
          : highlight
      )
    );
  };

  return (
    <div className="video-container">
      <form className="video-input-form" onSubmit={handleVideoIdSubmit}>
        <label htmlFor="videoIdInput">Enter Video ID:</label>
        <input
          type="text"
          id="videoIdInput"
          value={videoId}
          onChange={(e) => setVideoId(e.target.value)}
          placeholder="e.g., dQw4w9WgXcQ"
        />
        <button type="submit">Load Video</button>
      </form>

      <div className="video-responsive">
        <div id="youtube-player" ref={playerRef}></div>
      </div>

      <div className="controls">
        <p>Current Time: {currentTime.toFixed(1)} seconds</p>

        {duration > 0 && (
          <VideoTimeline
            duration={duration}
            highlights={highlights}
            currentTime={currentTime}
            updateHighlight={updateHighlight}
          />
        )}

        <div className="tag-buttons">
          <button onClick={() => handleTagSelection('verified-true')} className={`tag-button verified-true ${currentTag === 'verified-true' ? 'selected' : ''}`}>
            Verified True
          </button>
          <button onClick={() => handleTagSelection('likely-true')} className={`tag-button likely-true ${currentTag === 'likely-true' ? 'selected' : ''}`}>
            Likely True
          </button>
          <button onClick={() => handleTagSelection('opinion')} className={`tag-button opinion ${currentTag === 'opinion' ? 'selected' : ''}`}>
            Opinion
          </button>
          <button onClick={() => handleTagSelection('likely-false')} className={`tag-button likely-false ${currentTag === 'likely-false' ? 'selected' : ''}`}>
            Likely False
          </button>
          <button onClick={() => handleTagSelection('false')} className={`tag-button false ${currentTag === 'false' ? 'selected' : ''}`}>
            False
          </button>
        </div>

        {!isStarted ? (
          <button onClick={handleStart}>Start</button>
        ) : (
          <button onClick={handleStop}>Stop</button>
        )}
      </div>
    </div>
  );
}

function VideoTimeline({ duration, highlights, currentTime, updateHighlight }) {
  const timelineRef = useRef(null);
  const timelineWidth = 100;

  const handleMouseDown = (event, highlightIndex, handleType) => {
    event.preventDefault();

    const handleMouseMove = (moveEvent) => {
      const rect = timelineRef.current.getBoundingClientRect();
      const newTime = ((moveEvent.clientX - rect.left) / rect.width) * duration;
      updateHighlight(highlightIndex, handleType, newTime);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="timeline" ref={timelineRef}>
      {highlights.map((highlight, index) => (
        <div
          key={index}
          className={`highlight ${highlight.tag}`}
          style={{
            left: `${(highlight.start / duration) * timelineWidth}%`,
            width: `${((highlight.end - highlight.start) / duration) * timelineWidth}%`,
          }}
        >
          {/* Start Handle */}
          <div
            className="handle start"
            style={{ left: '0%' }}
            onMouseDown={(event) => handleMouseDown(event, index, 'start')}
          ></div>

          {/* End Handle */}
          <div
            className="handle end"
            style={{ right: '0%' }}
            onMouseDown={(event) => handleMouseDown(event, index, 'end')}
          ></div>
        </div>
      ))}
      <div
        className="current-time"
        style={{
          left: `${(currentTime / duration) * timelineWidth}%`,
        }}
      />
    </div>
  );
}

export default VideoPlayer;
