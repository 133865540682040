import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NewsFeed.css';
import he from 'he';

function NewsFeed() {
  const [articles, setArticles] = useState([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState({});
  const rss2jsonUrl = `https://api.rss2json.com/v1/api.json?rss_url=https://feeds.bbci.co.uk/news/rss.xml`;

  useEffect(() => {
    const fetchRSS = async () => {
      try {
        const response = await fetch(rss2jsonUrl);
        const data = await response.json();
        console.log("Fetched articles:", data.items); // For debugging
        setArticles(data.items);
      } catch (error) {
        console.error("Failed to fetch RSS feed:", error);
      }
    };

    fetchRSS();
  }, []);

  function timeSince(date) {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} years ago`;

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval} ${interval === 1 ? 'hour' : 'hours'} ago`;

    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval} ${interval === 1 ? 'minute' : 'minutes'} ago`;

    return `just now`;
  }

  const [sliderValues, setSliderValues] = useState(
  articles.map(() => ({ accuracy: 5, bias: 5, exclusive: 5 }))
);

  function handleContentTypeChange(index, value) {
    setSelectedContentTypes(prev => ({
      ...prev,
      [index]: value
    }));
  }

  return (
    <main className="newsfeed">
      <h2>Latest News</h2>
      <div className="articles">
        {articles.map((article, index) => (
<div className={`article ${selectedContentTypes[index] ? `selected-${selectedContentTypes[index]}` : ''}`} key={index}>
  {/* Section 1: Article Image */}
  <div className="article-image">
    {article.thumbnail && (
      <img src={article.thumbnail} alt={article.title} />
    )}
  </div>

  {/* Section 2: News Content */}
  <div className="article-content">
        <div className="article-source-inline">
      <img src="https://news.bbcimg.co.uk/nol/shared/img/bbc_news_120x60.gif" alt="BBC Logo" className="source-logo" />
      <span className="source-text">BBC News</span>
      </div>
     <a href={article.link} target="_blank" rel="noopener noreferrer">
        <h3>{he.decode(article.title)}</h3>
      </a>
    <p><strong>By:</strong> {article.author || 'Unknown Author'} | <em>{timeSince(article.pubDate)}</em></p>
    
    <p>{he.decode(article.description)}</p>
  </div>

  {/* Section 3: Ratings and Buttons */}
  <div className="article-ratings-buttons">
    <div className="ratings-box">
      <div className="content-type-buttons">
        <button 
          className={`content-type-button verified-true ${selectedContentTypes[index] === 'verified-true' ? 'selected' : ''}`} 
          onClick={() => handleContentTypeChange(index, 'verified-true')}
        >
          Verified True
        </button>
        <button 
          className={`content-type-button likely-true ${selectedContentTypes[index] === 'likely-true' ? 'selected' : ''}`} 
          onClick={() => handleContentTypeChange(index, 'likely-true')}
        >
          Likely True
        </button>
        <button 
          className={`content-type-button opinion ${selectedContentTypes[index] === 'opinion' ? 'selected' : ''}`} 
          onClick={() => handleContentTypeChange(index, 'opinion')}
        >
          Opinion
        </button>
        <button 
          className={`content-type-button likely-false ${selectedContentTypes[index] === 'likely-false' ? 'selected' : ''}`} 
          onClick={() => handleContentTypeChange(index, 'likely-false')}
        >
          Likely False
        </button>
        <button 
          className={`content-type-button false ${selectedContentTypes[index] === 'false' ? 'selected' : ''}`} 
          onClick={() => handleContentTypeChange(index, 'false')}
        >
          False
        </button>
      </div>
      <label className="slider-label">
        Importance:
        <div className="slider-container">
           <input
      type="range"
      min="0"
      max="10"
      step="1"
      value={sliderValues[index]?.importance || 5}
      className="importance-scale"
      onChange={(e) => {
        const newValues = [...sliderValues];
        newValues[index] = { ...newValues[index], importance: e.target.value };
        setSliderValues(newValues);
      }}
    />
        </div>
      </label>
      <label className="slider-label">
        Whole Story:
        <div className="slider-container">
    <input
      type="range"
      min="0"
      max="10"
      step="1"
      value={sliderValues[index]?.bias || 5}
      className="bias-scale"
      onChange={(e) => {
        const newValues = [...sliderValues];
        newValues[index] = { ...newValues[index], bias: e.target.value };
        setSliderValues(newValues);
      }}
    />
        </div>
      </label>
       <label className="slider-label">
        Exclusive:
        <div className="slider-container">
    <input
      type="range"
      min="0"
      max="10"
      step="1"
      value={sliderValues[index]?.exclusive || 5}
      className="exclusive-scale"
      onChange={(e) => {
        const newValues = [...sliderValues];
        newValues[index] = { ...newValues[index], exclusive: e.target.value };
        setSliderValues(newValues);
      }}
    />
        </div>
      </label>
    </div>
    <div className="action-buttons">
      <Link to={`/article/${index}`} state={{ article }}>
        <button className="detailed-rating-button">Detailed Rating</button>
      </Link>
    </div>
  </div>
</div>
        ))}
      </div>
    </main>
  );
}

export default NewsFeed;