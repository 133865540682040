import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import 'leaflet.heat';
import FilterMenu from './FilterMenu';

const NewsMap = () => {
    const mapRef = useRef(null);
    const markerClusterRef = useRef(null);
    const heatLayerRef = useRef(null);
    const [articles, setArticles] = useState([]);

    // Fetch articles from the JSON file
    useEffect(() => {
        fetch('/fake_city_and_rural_articles.json')
            .then(response => response.json())
            .then(data => setArticles(data))
            .catch(error => console.error("Error loading articles:", error));
    }, []);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map('map').setView([37.8, -96], 4); // Centered over the USA

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; OpenStreetMap contributors',
                maxZoom: 19,
            }).addTo(mapRef.current);

            // Initialize heat map layer
            heatLayerRef.current = L.heatLayer([], {
                radius: 5,
                blur: 10,
                minOpacity: 0.1,
                max: 0.00001,
                gradient: { 
                    0.1: 'blue',    // Low density
                    0.4: 'lime',    // Medium-low density
                    0.6: 'yellow',  // Medium density
                    0.8: 'orange',  // High density
                    1.0: 'red'      // Very high density
                }
            }).addTo(mapRef.current);

            // Initialize marker cluster group
            markerClusterRef.current = L.markerClusterGroup();

            // Toggle between heat map and clusters based on zoom level
            mapRef.current.on('zoomend', () => {
                const zoomLevel = mapRef.current.getZoom();
                
                if (zoomLevel <= 10) {
                    // Show heat map at zoom level 10 or below
                    if (mapRef.current.hasLayer(markerClusterRef.current)) {
                        mapRef.current.removeLayer(markerClusterRef.current);
                    }
                    if (!mapRef.current.hasLayer(heatLayerRef.current)) {
                        mapRef.current.addLayer(heatLayerRef.current);
                    }
                } else {
                    // Show clusters when zoomed in above level 10
                    if (mapRef.current.hasLayer(heatLayerRef.current)) {
                        mapRef.current.removeLayer(heatLayerRef.current);
                    }
                    if (!mapRef.current.hasLayer(markerClusterRef.current)) {
                        mapRef.current.addLayer(markerClusterRef.current);
                    }
                }
            });
        }

        if (articles.length > 0) {
            const heatData = articles.map(article => [...article.location, 0.5]); // Consistent intensity for each point

            // Update the heat layer data
            heatLayerRef.current.setLatLngs(heatData);

            // Populate cluster markers
            markerClusterRef.current.clearLayers();
            articles.forEach(article => {
                const marker = L.marker(article.location);
                marker.bindPopup(`<b>${article.title}</b>`);
                markerClusterRef.current.addLayer(marker);
            });
        }
    }, [articles]);

    return (
        <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
            <FilterMenu /> {/* Display the FilterMenu component on the top-right */}
            <div id="map" style={{ width: 'calc(100vw)', height: '100vh' }} />
        </div>
    );
};

export default NewsMap;
