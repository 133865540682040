import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Article.css';
import he from 'he';

const Article = () => {
  const location = useLocation();
  const { article } = location.state || {};
  const [content, setContent] = useState([]);
  const [author, setAuthor] = useState('Unknown Author');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [uniquePositions, setUniquePositions] = useState(new Map());
  const [highlightCounts, setHighlightCounts] = useState({
    green: 0,
    lightgreen: 0,
    lightcoral: 0,
    red: 0,
    yellow: 0,
  });
  const [paragraphOffsets, setParagraphOffsets] = useState([]); // Store paragraph offsets
  const [promptVisible, setPromptVisible] = useState(true); // State to manage prompt visibility

  useEffect(() => {
    if (!article) {
      setError('No article data provided');
      setLoading(false);
      return;
    }

    const fetchArticle = async () => {
      try {
        const response = await fetch(`http://localhost:8080/fetch-article?url=${encodeURIComponent(article.link)}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setContent(data.content || []);
        setAuthor(data.author || 'Unknown Author');
        console.log("Fetched article content:", data.content);
      } catch (error) {
        console.error("Error fetching article content:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [article]);

  useEffect(() => {
    // Calculate and store offsets for each paragraph on load
    const offsets = [];
    let cumulativeOffset = 0;

    document.querySelectorAll('.article-body p, .article-body h2').forEach((paragraph) => {
      offsets.push(cumulativeOffset);
      cumulativeOffset += paragraph.textContent.length;
    });

    setParagraphOffsets(offsets);
    console.log("Paragraph offsets:", offsets);
  }, [content]);

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setPopupPosition({
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY + rect.height
      });
      setPopupVisible(true);
      setPromptVisible(false); // Hide prompt when text is selected
      console.log("Text selected:", selection.toString());
    } else {
      setPopupVisible(false);
      if (uniquePositions.size === 0) {
      setPromptVisible(true);
    }
    }
  };

  const removeExistingHighlights = (globalStart, globalEnd) => {
    // Iterate over unique positions and delete any positions within the given range
    for (let i = globalStart; i < globalEnd; i++) {
      uniquePositions.delete(i);
    }
    console.log("Highlights removed in range:", globalStart, "to", globalEnd);
  };

  const highlightSelection = (color) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const startNode = range.startContainer;
    const endNode = range.endContainer;
    const startOffset = range.startOffset;
    const endOffset = range.endOffset;

    // Use findGlobalOffset for consistent paragraph and within-paragraph handling
    const globalStart = findGlobalOffset(startNode, startOffset);
    const globalEnd = findGlobalOffset(endNode, endOffset);

    console.log("Global Start:", globalStart, "Global End:", globalEnd);

    // Remove existing highlights only within this specific range
    // Assuming `uniquePositions` is already managing uniqueness by offset
    for (let i = globalStart; i < globalEnd; i++) {
      uniquePositions.delete(i);
    }

    // Create the span for highlighting
    const span = document.createElement('span');
    span.style.backgroundColor = color;
    range.surroundContents(span);
    selection.removeAllRanges();

    // Update unique positions with the new range and color
    for (let i = globalStart; i < globalEnd; i++)
 {
      uniquePositions.set(i, color);
    }

    calculateScaleBar();
    console.log("Updated unique positions:", Array.from(uniquePositions.entries()));
    console.log("Scale Bar:", calculateScaleBar());

    setPopupVisible(false); // Ensure popup is hidden after selection
  };

  const findGlobalOffset = (node, selectionOffset) => {
    const elements = document.querySelectorAll('.article-content h2, .article-body p');
    let globalOffset = 0;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      
      if (element.contains(node)) {
        const withinElementOffset = selectionOffset;
        globalOffset = paragraphOffsets[i] + withinElementOffset;
        break;
      }
    }

    console.log("Final global offset with header:", globalOffset);
    return globalOffset;
  };

  const calculateScaleBar = () => {
    const colorCounts = { green: 0, lightgreen: 0, yellow: 0, lightcoral: 0, red: 0 };
    uniquePositions.forEach((color) => {
      if (colorCounts[color] !== undefined) {
        colorCounts[color] += 1;
      }
    });

    const totalHighlights = Object.values(colorCounts).reduce((a, b) => a + b, 0);
    const scalePercentages = {
      green: totalHighlights ? (colorCounts.green / totalHighlights) * 100 : 0,
      lightgreen: totalHighlights ? (colorCounts.lightgreen / totalHighlights) * 100 : 0,
      yellow: totalHighlights ? (colorCounts.yellow / totalHighlights) * 100 : 0,
      lightcoral: totalHighlights ? (colorCounts.lightcoral / totalHighlights) * 100 : 0,
      red: totalHighlights ? (colorCounts.red / totalHighlights) * 100 : 0
    };

    console.log("Scale bar percentages:", scalePercentages);
    setHighlightCounts(scalePercentages);
  };

  const handleImageRating = (index, rating) => {
    console.log(`Image at index ${index} rated as: ${rating}`);
    const updatedContent = [...content];
    const image = updatedContent[index];

    if (rating === 'Accurate') {
      image.className = 'article-image accurate';
    } else if (rating === 'Misleading') {
      image.className = 'article-image misleading';
    } else if (rating === 'Inaccurate') {
      image.className = 'article-image inaccurate';
    }

    setContent(updatedContent);
  };

  if (loading) return <div className="spinner"></div>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="article-content" onMouseUp={handleTextSelection}>
      {popupVisible && (
        <div
          className="popup"
          style={{
            top: `${popupPosition.y}px`,
            left: `${popupPosition.x}px`
          }}
        >
          <button onClick={() => highlightSelection('green')}>Verified True</button>
          <button onClick={() => highlightSelection('lightgreen')}>Likely True</button>
          <button onClick={() => highlightSelection('yellow')}>Opinion</button>
          <button onClick={() => highlightSelection('lightcoral')}>Likely False</button>
          <button onClick={() => highlightSelection('red')}>False</button>
        </div>
      )}
      <div className="button-container">
        <a href={article.link} target="_blank" rel="noopener noreferrer" className="view-original-btn">
          View Original Article
        </a>
      </div>
      <h2>{he.decode(article.title)}</h2>
      <p className="article-metadata">
        <strong>By:</strong> {author} | <em>{new Date(article.pubDate).toLocaleDateString()}</em>
      </p>
      <div className="article-body">
        {content.map((item, index) => (
          item.type === 'paragraph' ? (
            <p key={index} dangerouslySetInnerHTML={{ __html: item.content }} />
          ) : (
            <div key={index} className="image-container">
              <img src={item.src} alt={item.alt} className={`article-image ${item.className || ''}`} />
              <div className="rating-buttons inline">
                <button className="accurate-button" onClick={() => handleImageRating(index, 'Accurate')}>Accurate</button>
                <button className="misleading-button" onClick={() => handleImageRating(index, 'Misleading')}>Misleading</button>
                <button className="inaccurate-button" onClick={() => handleImageRating(index, 'Inaccurate')}>Inaccurate</button>
              </div>
            </div>
          )
        ))}
      </div>
      
      <div className="scale-bar">
      {promptVisible && (
        <p className="highlight-prompt">Highlight text to start rating the article</p>
      )}
        <div style={{ width: `${highlightCounts.green}%`, backgroundColor: 'green' }}></div>
        <div style={{ width: `${highlightCounts.lightgreen}%`, backgroundColor: 'lightgreen' }}></div>
        <div style={{ width: `${highlightCounts.yellow}%`, backgroundColor: 'yellow' }}></div>
        <div style={{ width: `${highlightCounts.lightcoral}%`, backgroundColor: 'lightcoral' }}></div>
        <div style={{ width: `${highlightCounts.red}%`, backgroundColor: 'red' }}></div>
      </div>
    </div>
  );
};

export default Article;