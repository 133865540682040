import React from 'react';
import './Header.css';
import logo from '../imgs/The_Hat.png';
import masthead from '../imgs/masthead.png';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header">
      <div className="header-left">
        <Link to="/">
          <img src={logo} alt="Hat_Logo" className="header-hat" />
        </Link>
      </div>
      <div className="header-center">
        <img src={masthead} alt="eJourno" className="header-masthead" />
      </div>
      <nav className="header-right">
        <Link to="/news-map">
          <button className="header-btn">Map</button> 
        </Link>
        <Link to="/video">
          <button className="header-btn">Video Analysis</button> 
        </Link> 
        <Link to="/profile">
          <button className="header-btn">Profile</button>
        </Link>
        <Link to="/login">
          <button className="header-btn">Login</button>
        </Link>
      </nav>
    </header>
  );
}

export default Header;

