import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import NewsFeed from './components/NewsFeed';
import Article from './components/Article';
import Profile from './components/Profile';
import Login from './components/Login';
import VideoPlayer from './components/Video';
import NewsMap from './components/NewsMap';

import './App.css';

function App() {
  const location = useLocation();

  
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        {!location.pathname.startsWith('/article') && !location.pathname.startsWith('/video') && !location.pathname.startsWith('/news-map') && <Sidebar />}
        <Routes>
          <Route path="/" element={<NewsFeed />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/video" element={<VideoPlayer />} />
          <Route path="/news-map" element={<NewsMap />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;


