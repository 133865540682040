// src/components/FilterMenu.js
import React, { useState } from 'react';
import './FilterMenu.css';

const FilterMenu = () => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [timeFilter, setTimeFilter] = useState('');

    const categories = ["Politics", "Tech", "Health", "Sports", "Entertainment"];
    const timeOptions = ["Last Hour", "Last 12 Hours", "Last 24 Hours", "3 Days", "7 Days"];

    const handleCategoryClick = (category) => {
        setSelectedCategories(prev =>
            prev.includes(category)
                ? prev.filter(item => item !== category)
                : [...prev, category]
        );
    };

    return (
        <div className="filter-menu">
            <h4>Filter Options</h4>

            {/* Categories Filter */}
            <div className="filter-section">
                <h5>Categories</h5>
                {categories.map(category => (
                    <button
                        key={category}
                        onClick={() => handleCategoryClick(category)}
                        className={`category-btn ${selectedCategories.includes(category) ? 'selected' : ''}`}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Search Term Filter */}
            <div className="filter-section">
                <h5>Search Term</h5>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Enter search term"
                    className="search-input"
                />
            </div>

            {/* Time Filter */}
            <div className="filter-section">
                <h5>Time</h5>
                {timeOptions.map(option => (
                    <button
                        key={option}
                        onClick={() => setTimeFilter(option)}
                        className={`time-btn ${timeFilter === option ? 'selected' : ''}`}
                    >
                        {option}
                    </button>
                ))}
                <div>
                    <label>
                        From:
                        <input type="date" className="date-input" />
                    </label>
                    <label>
                        To:
                        <input type="date" className="date-input" />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FilterMenu;
