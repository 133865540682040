import React from 'react';

function Sidebar() {
  return (
    <aside className="sidebar">
      <h2>Categories</h2>
      <ul>
        <li><a href="/category/politics">Politics</a></li>
        <li><a href="/category/sports">Sports</a></li>
        <li><a href="/category/technology">Technology</a></li>
        <li><a href="/category/health">Health</a></li>
        <li><a href="/category/entertainment">Entertainment</a></li>
      </ul>
    </aside>
  );
}

export default Sidebar;
