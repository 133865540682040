import React, { useEffect, useState } from 'react';
import './Profile.css';

function Profile() {
  const [userData, setUserData] = useState({});
  const [userRatings, setUserRatings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Debugging Fetch Request
        const response = await fetch('/api/user');
        const text = await response.text();
        console.log('Response:', text);

        // Regular User Data Fetch
        const userResponse = await fetch('/api/user');
        if (!userResponse.ok) {
          throw new Error(`Error: ${userResponse.status} ${userResponse.statusText}`);
        }
        const user = await userResponse.json();

        const userRatingsResponse = await fetch('/api/user/ratings');
        if (!userRatingsResponse.ok) {
          throw new Error(`Error: ${userRatingsResponse.status} ${userRatingsResponse.statusText}`);
        }
        const ratings = await userRatingsResponse.json();

        setUserData(user);
        setUserRatings(ratings);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []); // Empty dependency array to run once when the component mounts

  if (loading) return <div className="spinner"></div>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="profile">
      <h2>Profile</h2>
      <div className="profile-info">
        <h3>{userData.name}</h3>
        <p>Email: {userData.email}</p>
        <p>Joined: {new Date(userData.joined).toLocaleDateString()}</p>
      </div>

      <h3>Rating History</h3>
      <ul className="ratings-list">
        {userRatings.map((rating, index) => (
          <li key={index}>
            <p><strong>Article:</strong> {rating.articleTitle}</p>
            <p><strong>Rating:</strong> {rating.rating}</p>
            <p><strong>Date:</strong> {new Date(rating.date).toLocaleDateString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Profile;
